<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      imageLoad: false,
      allImages: [
        "image/통후추.png",
        "image/멘토스.png",
        "image/백산수.png",
        "image/빌라블랑카오일.png",
        "image/신라면.png",
        "image/알쿠니아.png",
        "image/엑설런스.png",
        "image/옥수수깡.png",
        "image/웰치스.png",
        "image/카프리썬.png",
        "image/고기곰탕.png",
        "image/파워오투.png",
        "image/프링글스.png",
        "image/하우스와사비.png",
        "image/혼다시.png",
        "image/츄파춥스.png"
      ]
    };
  },
  created() {
    this.loadImage();
  },
  methods: {
    loadImage() {
      try {
        Promise.all(
          this.allImages.map(async src => {
            return await this.preLoadImage(src);
          })
        )
          .then(() => {
            this.imageLoad = true;
          })
          .catch(e => {
            console.error(e);
          });
      } catch (e) {
        console.error(e);
      }
    },
    preLoadImage(src) {
      return new Promise(resolve => {
        let img = new Image();
        img.src = `https://fbti-ns.com/${src}`;
        img.onload = () => {
          resolve(true);
        };
        img.onerror = () => {
          resolve(true);
        };
      });
    }
  }
};
</script>